<template>
    <div class="level_index level_indexI">
        <div class="search">
            <el-form :inline="true">
                <el-form-item prop="title">
                    <el-input clearable @keydown.enter.native="toSearch" v-model="searchTitle" placeholder="请输入医院名称" />
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="toSearch">
                        <i class="fa fa-search" aria-hidden="true"></i>
                        查询
                    </el-button>

                    <!-- <el-button type="primary" @click="toSearch">
            <i class="fa fa-plus" aria-hidden="true"></i>
            添加配置项
          </el-button> -->

                    <!-- <el-button class="block" @click="del">批量删除</el-button> -->
                </el-form-item>
            </el-form>
        </div>

        <!-- <Fanfu-Table class="FanfuTableBox" v-model="select" :data="data" :search-height="50" :loading="loading"
                    :get-list="getList" stripe border ref="FanfuTable"> -->

        <el-table class="el-tableII FanfuTableBox" :data="data.list" stripe height="100%" border ref="multipleTable">
            <el-table-column show-overflow-tooltip label="序号" type="index" align="center" fixed="left" min-width="75" />

            <el-table-column show-overflow-tooltip prop="dep_name" label="医院" align="center" min-width="150" />

            <el-table-column show-overflow-tooltip prop="title" label="标题" align="center" min-width="100" />
            <el-table-column show-overflow-tooltip prop="info" label="消息内容" align="center" min-width="200" />
            <!-- <el-table-column show-overflow-tooltip    prop="name" label="状态" align="center"  width="90"/>
            <el-table-column show-overflow-tooltip    prop="name" label="备注" align="center" /> -->


            <el-table-column show-overflow-tooltip prop="" label="状态" align="center" width="90">
                <template slot-scope="scope">
                    <span v-if="scope.row.type=='1'">暂停营业</span>
                    <span v-else-if="scope.row.type=='2'">未产生医废</span>
                    <span v-else>医院关停</span>
                </template>
            </el-table-column>




            <el-table-column show-overflow-tooltip label="操作" min-width="75" align="center">
                <template slot-scope="scope">
                    <div class="operate">
                        <el-button @click="view(scope.row)" v-if="getPurviewNew('sys:messageStand:detail')"> 查看
                        </el-button>
                        <!--  <el-button type="primary" @click="edit(scope.row)">
                                    编辑
                                </el-button>
                                <el-button type="danger" @click="del(scope.row)">
                                    删除
                                </el-button> -->
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <!-- </Fanfu-Table> -->

        <el-pagination layout="total, prev, pager, next, jumper" background :current-page.sync="data.page"
            :page-size="data.size" :total="data.total" @current-change="handleCurrentChange" />

        <Search ref="searchRef" v-model="search" @refresh="getList(data.size)" />

    </div>
</template>

<script>
    import Search from './components/DetailsSearch'

    import resizeDetector from 'element-resize-detector'

    export default {
        components: {
            Search
        },
        data() {
            return {
                activeName: '001',

                search: {
                    title: '',
                    name2: '',
                    name3: '',
                    name4: '',
                    name5: '',
                    name6: ''
                },
                loading: false,
                select: [],

                searchTitle: "",
                data: {
                    list: [],
                    page: 1,
                    size: 15,
                    total: 0
                }
            }
        },

        mounted() {
            this.modifyLayout()
        },
        created() {
            this.modifyLayout()

            this.getList()
        },

        watch: {
            searchTitle(newValue, oldValue) {
                if (oldValue.length > 0 && newValue.length == 0) {

                    this.toSearch()

                }

            }
        },



        methods: {
            handleClick(tab, event) {
                console.log(tab.index)
            },

            // AllTotal() {
            //     let a = list.length;
            //     for (let i = 0; i < list.length; i++) {
            //         var dic = list[i];
            //         if (dic.hasOwnProperty("children")) {
            //             a = a + dic.children.length
            //         }
            //     }
            //     return a;
            // },

            // getList(size) {
            //     if (size) {
            //         this.data.page = 1
            //         this.data.size = size
            //     }
            //     this.loading = true
            //     this.select = []
            //     this.$nextTick(() => {
            //         const start = this.data.size * (this.data.page - 1)
            //         const end = start + this.data.size
            //         this.data.total = list.length
            //         const data = []
            //         for (let i = start; i < end; i++) {
            //             if (list[i]) {
            //                 data.push(list[i])
            //             }
            //         }
            //         this.data.list = data
            //         setTimeout(() => {
            //             this.loading = false
            //         }, 1000)
            //         setTimeout(() => {
            //             this.modifyLayout();
            //         }, 1100)
            //     })
            // },

            getList() {

                this.$http.get('/api/hazardousabnormal', {

                    keywords: this.searchTitle,

                    page_size: this.data.size,
                    page: this.data.page

                }).then((res) => {
                    console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                    if (res.code == 200) {

                        this.data.list = res.data.data

                        this.data.total = res.data.total

                        setTimeout(() => {
                            this.modifyLayout();
                        }, 1100)

                    }

                    if (res.code != 200) { //请求错误
                        this.$message.error(res.msg);

                    }
                }).catch((err) => {
                    console.log(err)
                });

            },

            toSearch() {
                // this.$refs.searchRef.open()
                this.data.page = 1
                this.getList()

            },

            view(row) {

                this.$refs.searchRef.view(row)
            },
            edit(row) {
                console.log(row)
            },
            del(row) {
                console.log(row)

                const h = this.$createElement
                this.$msgbox({
                    title: '删除',
                    message: h('p', null, [h('span', null, '该操作将删除所选数据，您确定要删除吗？删除后将无法恢复！')]),
                    showCancelButton: true,
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    beforeClose: (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true
                            instance.confirmButtonText = '执行中...'
                            setTimeout(() => {
                                done()
                                setTimeout(() => {
                                    instance.confirmButtonLoading = false
                                }, 300)
                            }, 3000)
                        } else {
                            done()
                        }
                    }
                }).then((action) => {
                    this.$message({
                        type: 'info',
                        message: 'action: ' + action
                    })
                })
            },



            modifyLayout() {
                const erd = resizeDetector()

                erd.listenTo(document.getElementsByClassName('search'), (element) => {
                    const width = element.offsetWidth
                    const height = element.offsetHeight

                    var a = 141 + 60 - 35 + height - 30

                    if ($('.level_indexI .FanfuTableBox')[0]) {
                        $('.level_indexI .FanfuTableBox')[0].setAttribute('style', 'height: calc(100vh - ' + a +
                            'px) !important')
                    }

                    var b = 141 + 60 + 33 + height - 30
                    if ($('.level_indexI .el-table')[0]) {
                        $('.level_indexI .el-table')[0].setAttribute('style', 'height: calc(100vh - ' + b +
                            'px) !important')
                    }

                    //
                })
            },

            handleCurrentChange(val) {
                console.log(`当前页: ${val}`)

                this.data.page = val

                this.getList()
            }
        }
    }
</script>





<style lang="scss" scoped>
    .el-button--success {
        color: #ffffff;
        background-color: #5bd995;
        border-color: #5bd995;
    }

    /deep/ .el-tabs__item.is-active {
        // border: 1px blue dotted;
        // box-sizing: border-box;
        background: #6a83fc;
        color: #ffffff;
    }

    /deep/.el-tabs--card>.el-tabs__header .el-tabs__nav {
        border-radius: 6px 6px 0 0;
        overflow: hidden;
    }
</style>


<style scoped>
    /*  .level_indexI .FanfuTableBox {
    border: 1px blue dotted;
    box-sizing: border-box;
  } */
    /*  .level_indexI .el-table.el-table--fit.el-table--striped.el-table--border.el-table--fluid-height.el-table--scrollable-y.el-table--enable-row-transition.el-table--mini { */

    /*  .level_indexI .el-table {
    border: 1px red dotted;
    box-sizing: border-box;
  } */
</style>